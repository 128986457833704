/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { colors, Button } from '@material-ui/core';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },

  //root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const SidebarNav = props => {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [, setSelectedChild] = React.useState('');
  const { pages, className, ...rest } = props;

  const selectChild = (childID, categoryID) => {
    setSelectedChild(childID);
    setSelectedCategory(categoryID);
  };
  const selectCategory = categoryID => {
    let currentID = selectedCategory == categoryID ? '' : categoryID;
    setSelectedCategory(currentID);
  };
  return (
    <List
      {...rest}
      aria-labelledby="nested-list-subheader"
      className={clsx(classes.root, className)}
      component="nav">
      {pages.map(page => (
        <div>
          {page.child.length <= 0 && (
            <ListItem
              className={classes.item}
              disableGutters
              key={page.category.title}
              onClick={() => selectCategory(page.category.title)}>
              <Button
                activeClassName={classes.active}
                className={classes.button}
                component={CustomRouterLink}
                to={page.category.href}>
                <div className={classes.icon}>{page.category.icon}</div>
                {page.category.title}
              </Button>
            </ListItem>
          )}
          {page.child.length > 0 && (
            <div>
              <ListItem
                button
                className={classes.item}
                disableGutters
                key={page.category.title}
                onClick={() => {
                  selectCategory(page.category.title);
                }}>
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  to={page.category.href}>
                  <div className={classes.icon}>{page.category.icon}</div>
                  {page.category.title}
                </Button>
                {/*<ListItemIcon>{page.category.icon}</ListItemIcon>
                <ListItemText primary={page.category.title} /> */}

                {selectedCategory == page.category.title ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </ListItem>
              <Collapse
                in={page.category.title == selectedCategory}
                timeout="auto"
                unmountOnExit>
                <List component="div" disablePadding>
                  {page.child.map(child => (
                    <ListItem
                      className={classes.item}
                      disableGutters
                      key={child.title}
                      onClick={() => {
                        selectChild(child.title, page.category.title);
                      }}
                      style={{ paddingLeft: '24px' }}>
                      <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={child.href}>
                        <div className={classes.icon}>{child.icon}</div>
                        {child.title}
                      </Button>
                      {/*
                    <ListItem button className={classes.nested} key={child.title}>
                    <ListItemIcon>{child.icon}</ListItemIcon>
                    <ListItemText primary={child.title} />
                     */}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </div>
          )}
        </div>
      ))}

      {/*
      <ListItem button>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText primary="Sent mail" />
      </ListItem>
      <ListItem button>
        <ListItemIcon>
          <DraftsIcon />
        </ListItemIcon>
        <ListItemText primary="Drafts" />
      </ListItem>

      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary="Inbox" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <StarBorder />
            </ListItemIcon>
            <ListItemText primary="Starred" />
          </ListItem>
        </List>
      </Collapse>
      */}

      {/*pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            to={page.href}>
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))*/}
    </List>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
};

export default SidebarNav;
