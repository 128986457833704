import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';

const columns = [
  { id: 'parcela', label: 'Número da Parcela', minWidth: 70 },
  {
    id: 'baixar',
    label: '.',
    minWidth: 70,
    //align: 'center',
    type: 'button'
  }
];

function createData(parcela, baixar) {
  return { parcela, baixar };
}

const rows = [
  createData('1'),
  createData('2'),
  createData('3'),
  createData('4'),
  createData('5'),
  createData('6'),
  createData('7'),
  createData('8'),
  createData('9'),
  createData('10'),
  createData('11'),
  createData('12')
];

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(4)
  },
  container: {
    maxHeight: 600
  }
}));

const ListaBoleto = () => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper style={{ padding: '12px' }}>
        <div>
          <Typography variant="h6">Boletos para pagamento</Typography>
        </div>
        <TableContainer className={classes.container}>
          <Table aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell
                    align={column.align}
                    key={column.id}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  return (
                    <TableRow
                      hover
                      key={row.code}
                      role="checkbox"
                      tabIndex={-1}>
                      {columns.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell align={column.align} key={column.id}>
                            {column.type == 'button' ? (
                              <Button
                                color="primary"
                                onClick={() =>
                                  alert(
                                    'Baixando o boleto da parcela ' +
                                      row[column.id]
                                  )
                                }
                                startIcon={<SaveIcon />}
                                variant="contained">
                                Baixar boleto
                              </Button>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={rows.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 100]}
        />
      </Paper>
    </div>
  );
};
export default ListaBoleto;
