import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'ol/ol.css';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

// Start Openlayers imports
import { Map, View } from 'ol';
import { fromLonLat } from 'ol/proj';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource, XYZ as XYZSource } from 'ol/source';

import {
  ZoomSlider,
  MousePosition,
  OverviewMap,
  FullScreen,
  ScaleLine,
  defaults as DefaultControls
} from 'ol/control';
import {
  Style,
  Circle as CircleStyle,
  Fill as FillStyle,
  Icon,
  Stroke as StrokeStyle
} from 'ol/style';

import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Point from 'ol/geom/Point';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(4)
  }
}));

var colegioCEM = fromLonLat([-42.96403, -22.41385]);

var view = new View({
  center: colegioCEM,
  zoom: 14.8,
  projection: 'EPSG:3857'
});
// End Openlayers imports

var geolocation = new Geolocation({
  // enableHighAccuracy must be set to true to have the heading value.
  trackingOptions: {
    enableHighAccuracy: true
  },
  projection: view.getProjection()
});

// update the HTML page when the position changes.
geolocation.on('change', function() {
  // el('accuracy').innerText = geolocation.getAccuracy() + ' [m]';
  // el('altitude').innerText = geolocation.getAltitude() + ' [m]';
  //el('altitudeAccuracy').innerText = geolocation.getAltitudeAccuracy() + ' [m]';
  //el('heading').innerText = geolocation.getHeading() + ' [rad]';
  //el('speed').innerText = geolocation.getSpeed() + ' [m/s]';
  //view.setCenter(geolocation.getPosition());
});

// handle geolocation error.
geolocation.on('error', function(error) {
  var info = document.getElementById('info');
  if (!info) return;
  info.innerHTML = error.message;
  info.style.display = '';
});

var accuracyFeature = new Feature();
geolocation.on('change:accuracyGeometry', function() {
  accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
});

var positionFeature = new Feature({ type: 'minha_localizacao' });
var posicaoDaEscola = new Feature({
  type: 'escola',
  geometry: new Point(colegioCEM),
  name: 'Centro de ensino moderno teresópolis (CEM)'
});

var posicaoDemonstracao = new Feature({
  type: 'responsavel_localizacao',
  geometry: new Point(fromLonLat([-42.94403, -22.41485])),
  name: 'Centro de ensino moderno teresópolis (CEM)'
});

var styles = {
  route: new Style({
    stroke: new StrokeStyle({
      width: 6,
      color: [237, 212, 0, 0.8]
    })
  }),
  minha_localizacao: new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new FillStyle({
        color: '#3399CC'
      }),
      stroke: new StrokeStyle({
        color: '#fff',
        width: 2
      })
    })
  }),
  escola: new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: '/assets/ico/logo/cem/cem_marcador_gps.png'
    })
  }),
  responsavel_localizacao: new Style({
    image: new Icon({
      anchor: [0.5, 1],
      src: '/assets/ico/common/gps_marker.png'
    })
  }),
  geoMarker: new Style({
    image: new CircleStyle({
      radius: 7,
      fill: new FillStyle({ color: 'black' }),
      stroke: new StrokeStyle({
        color: 'white',
        width: 2
      })
    })
  })
};

geolocation.on('change:position', function() {
  var coordinates = geolocation.getPosition();
  positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
});

var vectorLayer;
class EstouChegando extends React.Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {
    const h =
      window.innerWidth >= 992
        ? window.innerHeight - 100
        : window.innerHeight - 100; //400;
    this.setState({ height: h });
  }
  componentWillMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
    geolocation.setTracking(true);
  }
  componentDidMount() {
    // Create an Openlayer Map instance with two tile layers
    const map = new Map({
      //  Display the map in the div with the id of map
      target: 'map',
      layers: [
        new TileLayer({
          source: new XYZSource({
            url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            projection: 'EPSG:3857'
          })
        })
        /*new TileLayer({
          source: new TileWMSSource({
            url: 'https://ahocevar.com/geoserver/wms',
            params: {
              layers: 'topp:states',
              TILED: true
            },
            projection: 'EPSG:4326'
          }),
          name: 'USA'
        })*/
      ],
      // Add in the following map controls
      controls: DefaultControls().extend([
        new ZoomSlider(),
        new MousePosition(),
        new ScaleLine(),
        new OverviewMap(),
        new FullScreen()
      ]),
      // Render the tile layers in a map view with a Mercator projection
      view: view
    });
    vectorLayer = new VectorLayer({
      map: map,
      source: new VectorSource({
        features: [
          accuracyFeature,
          positionFeature,
          posicaoDaEscola,
          posicaoDemonstracao
        ]
      }),
      style: function(feature) {
        return styles[feature.get('type')];
      }
    });
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const style = {
      width: '100%',
      height: this.state.height,
      backgroundColor: '#cccccc'
    };
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item style={{ padding: '12px' }} xs={9}>
          <Paper className={classes.root} elevation={3}>
            {/* 
          <div>
            <p>
              <div id="info" style={{ display: 'none' }} />
              position accuracy : <code id="accuracy" />
              &nbsp;&nbsp; altitude : <code id="altitude" />
              &nbsp;&nbsp; altitude accuracy : <code id="altitudeAccuracy" />
              &nbsp;&nbsp; heading : <code id="heading" />
              &nbsp;&nbsp; speed : <code id="speed" />
            </p>
          </div>
          */}

            <div id="map" style={style} />
          </Paper>
        </Grid>
        <Grid item style={{ padding: '12px' }} xs={3}>
          <Paper className={classes.root} elevation={3}>
            <Typography variant="h6">Lista de pessoas em rota</Typography>
            <List dense>
              <ListItem button>
                <ListItemText
                  onClick={() => {
                    vectorLayer.getSource().addFeature(
                      new Feature({
                        type: 'responsavel_localizacao',
                        geometry: new Point(fromLonLat([-42.93403, -22.41385])),
                        name: 'Centro de ensino moderno teresópolis (CEM)'
                      })
                    );
                  }}
                  primary="Single-line item"
                  secondary="Secondary text"
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(useStyles)(EstouChegando);
